var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"290","persistent":"","hide-overlay":""},model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',{staticClass:"text-center"},[_vm._v(" Loading "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1),(!_vm.progress)?_c('v-container',{attrs:{"fluid":"","fill-height":"","grid-list-lg":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-toolbar',{attrs:{"flat":"","dense":"","color":"secondary","dark":""}},[_c('v-icon',[_vm._v("fal fa-truck")]),_c('v-toolbar-title',{staticClass:"pl-2"},[_vm._v(" Carrier Accounts ")]),_c('v-spacer'),_c('carrier-account-entry',{attrs:{"entry":_vm.carrierAccount}})],1),(_vm.carrierAccountsStore.carrierAccounts.length > 0)?_c('v-list',{staticClass:"pt-0",attrs:{"two-line":""}},[_vm._l((_vm.carrierAccountsStore.carrierAccounts),function(item,i){return [_c('v-list-item',{key:i,on:{"click":function($event){return _vm.editCarrierAccount(item)}}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":"primary","large":""}},[_vm._v(" "+_vm._s(item.shipping_carrier.d_icon)+" ")])],1),_c('v-list-item-content',[(_vm.usersStore.authUser.customers.length > 0)?_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.customer.d_companyName)+" ")]):_vm._e(),_c('v-list-item-title',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(item.shipping_carrier.d_name)+" - "+_vm._s(item.d_company)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.d_account))])],1),_c('v-list-item-action',[_c('v-list-item-action-text',{class:'text-xs-right subtitle-1 ' +
                      [
                        item.f_status === 1
                          ? 'green--text lighten-1--text'
                          : 'error--text'
                      ]},[_vm._v(" "+_vm._s(_vm.translateStatus(item.f_status))+" ")])],1)],1),_c('v-divider')]})],2):_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"red--text subtitle-1 text-xs-center"},[_vm._v(" No Carrier Accounts Exist ")])],1)],1)],1),_c('v-toolbar',{attrs:{"flat":"","dense":"","color":"secondary","dark":""}},[_c('v-icon',[_vm._v("fal fa-address-book")]),_c('v-toolbar-title',{staticClass:"pl-2"},[_vm._v(" Addresses ")]),_c('v-spacer'),_c('address-entry',{attrs:{"entry":_vm.address}})],1),_c('v-toolbar',{staticClass:"pt-0",attrs:{"flat":"","color":"white"}},[_c('v-menu',{attrs:{"offset-y":"","right":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"rounded":"","color":"primary"}},on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" fal fa-filter ")]),(
                    _vm.addressesStore.filters.customerID ||
                      _vm.addressesStore.filters.locationName ||
                      _vm.addressesStore.filters.company
                  )?_c('span',[_vm._v(" Filtered")]):_c('span',[_vm._v("Filter ")])],1)]}}],null,false,2553254475),model:{value:(_vm.addressFilter),callback:function ($$v) {_vm.addressFilter=$$v},expression:"addressFilter"}},[_c('v-card',{attrs:{"max-width":"400px"}},[_c('v-card-text',[_c('v-container',{attrs:{"fluid":"","grid-list-sm":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[(
                        _vm.addressesStore.filters.customerID ||
                          _vm.addressesStore.filters.locationName ||
                          _vm.addressesStore.filters.company
                      )?_c('v-flex',{attrs:{"xs12":""}},[_c('v-btn',{attrs:{"block":"","rounded":"","color":"primary"},on:{"click":function($event){return _vm.clearFilter()}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" fal fa-times ")]),_vm._v("Clear Filters ")],1)],1):_vm._e(),(_vm.usersStore.authUser.customers.length > 0)?_c('v-flex',{attrs:{"xs12":""}},[_c('v-autocomplete',{attrs:{"items":_vm.usersStore.authUser.customers_view,"item-text":"d_companyName","item-value":"id","label":"Customer","placeholder":" ","persistent-placeholder":"","clearable":"","clear-icon":"fal fa-times-circle"},model:{value:(_vm.filterBar.customerID),callback:function ($$v) {_vm.$set(_vm.filterBar, "customerID", $$v)},expression:"filterBar.customerID"}})],1):_vm._e(),_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"label":"Location Name","placeholder":" ","persistent-placeholder":""},model:{value:(_vm.filterBar.locationName),callback:function ($$v) {_vm.$set(_vm.filterBar, "locationName", $$v)},expression:"filterBar.locationName"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"label":"Company Name","placeholder":" ","persistent-placeholder":""},model:{value:(_vm.filterBar.company),callback:function ($$v) {_vm.$set(_vm.filterBar, "company", $$v)},expression:"filterBar.company"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"rounded":"","block":"","color":"primary"},on:{"click":function($event){return _vm.filter()}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" fal fa-check ")]),_vm._v("Submit ")],1)],1)],1)],1)],1),_c('pagination',{attrs:{"show":_vm.addressesStore.addresses.data &&
            _vm.addressesStore.addresses.data.length > 0 &&
            _vm.addressesStore.addresses.last_page > 1
              ? true
              : false,"current-page":_vm.addressesStore.addresses.current_page,"last-page":_vm.addressesStore.addresses.last_page},on:{"paginate-event":function($event){return _vm.filter($event)}}}),(_vm.addressesStore.addresses.data.length > 0)?_c('v-list',{staticClass:"pt-0",attrs:{"two-line":""}},[_vm._l((_vm.addressesStore.addresses.data),function(item,i){return [_c('v-list-item',{key:i,on:{"click":function($event){return _vm.editAddress(item)}}},[_c('v-list-item-content',[(_vm.usersStore.authUser.customers.length > 0)?_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.customer.d_companyName)+" ")]):_vm._e(),_c('v-list-item-title',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(item.d_locationName)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.d_company)+" - "+_vm._s(item.d_street1)+" "+_vm._s(item.d_city)+", "+_vm._s(item.d_state)+" "+_vm._s(item.d_zip))])],1),_c('v-list-item-action',[_c('v-list-item-action-text',{class:'text-xs-right subtitle-1 ' +
                      [
                        item.f_status === 1
                          ? 'green--text lighten-1--text'
                          : 'error--text'
                      ]},[_vm._v(" "+_vm._s(_vm.translateStatus(item.f_status))+" ")])],1)],1),_c('v-divider')]})],2):_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"red--text subtitle-1 text-xs-center"},[_vm._v(" No Addresses Exist ")])],1)],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }