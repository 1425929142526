<template>
  <div>
    <!--Progress Bar-->
    <v-dialog v-model="progress" max-width="290" persistent hide-overlay>
      <v-card color="primary" dark>
        <v-card-text class="text-center">
          Loading
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-container v-if="!progress" fluid fill-height grid-list-lg>
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat dense color="secondary" dark>
            <v-icon>fal fa-truck</v-icon>
            <v-toolbar-title class="pl-2">
              Carrier Accounts
            </v-toolbar-title>
            <v-spacer />
            <carrier-account-entry :entry="carrierAccount" />
          </v-toolbar>

          <!--  Carrier Accounts List -->
          <v-list
            v-if="carrierAccountsStore.carrierAccounts.length > 0"
            two-line
            class="pt-0"
          >
            <template v-for="(item, i) in carrierAccountsStore.carrierAccounts">
              <v-list-item :key="i" @click="editCarrierAccount(item)">
                <v-list-item-avatar>
                  <v-icon color="primary" large>
                    {{ item.shipping_carrier.d_icon }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-if="usersStore.authUser.customers.length > 0"
                    class="font-weight-bold"
                  >
                    {{ item.customer.d_companyName }}
                  </v-list-item-title>

                  <v-list-item-title class="text--primary">
                    {{ item.shipping_carrier.d_name }} - {{ item.d_company }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    item.d_account
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text
                    :class="
                      'text-xs-right subtitle-1 ' +
                        [
                          item.f_status === 1
                            ? 'green--text lighten-1--text'
                            : 'error--text'
                        ]
                    "
                  >
                    {{ translateStatus(item.f_status) }}
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
              <v-divider />
            </template>
          </v-list>

          <v-list v-else>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="red--text subtitle-1 text-xs-center">
                  No Carrier Accounts Exist
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <!--  Address List -->
          <v-toolbar flat dense color="secondary" dark>
            <v-icon>fal fa-address-book</v-icon>
            <v-toolbar-title class="pl-2">
              Addresses
            </v-toolbar-title>
            <v-spacer />
            <address-entry :entry="address" />
          </v-toolbar>

          <!--  Filter Toolbar -->
          <v-toolbar flat color="white" class="pt-0">
            <!--  Filter Button -->
            <v-menu
              v-model="addressFilter"
              offset-y
              right
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <v-btn rounded color="primary" v-on="on">
                  <v-icon small left>
                    fal fa-filter </v-icon
                  ><span
                    v-if="
                      addressesStore.filters.customerID ||
                        addressesStore.filters.locationName ||
                        addressesStore.filters.company
                    "
                  >
                    Filtered</span
                  ><span v-else>Filter </span>
                </v-btn>
              </template>

              <v-card max-width="400px">
                <v-card-text>
                  <v-container fluid grid-list-sm>
                    <v-layout row wrap>
                      <v-flex
                        v-if="
                          addressesStore.filters.customerID ||
                            addressesStore.filters.locationName ||
                            addressesStore.filters.company
                        "
                        xs12
                      >
                        <v-btn
                          block
                          rounded
                          color="primary"
                          @click="clearFilter()"
                        >
                          <v-icon small left>
                            fal fa-times </v-icon
                          >Clear Filters
                        </v-btn>
                      </v-flex>

                      <v-flex
                        xs12
                        v-if="usersStore.authUser.customers.length > 0"
                      >
                        <v-autocomplete
                          v-model="filterBar.customerID"
                          :items="usersStore.authUser.customers_view"
                          item-text="d_companyName"
                          item-value="id"
                          label="Customer"
                          placeholder=" "
                          persistent-placeholder
                          clearable
                          clear-icon="fal fa-times-circle"
                        />
                      </v-flex>

                      <v-flex xs12>
                        <v-text-field
                          v-model="filterBar.locationName"
                          label="Location Name"
                          placeholder=" "
                          persistent-placeholder
                        />
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          v-model="filterBar.company"
                          label="Company Name"
                          placeholder=" "
                          persistent-placeholder
                        />
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn rounded block color="primary" @click="filter()">
                    <v-icon small left>
                      fal fa-check </v-icon
                    >Submit
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-toolbar>

          <!-- Pagination Toolbar -->
          <pagination
            :show="
              addressesStore.addresses.data &&
              addressesStore.addresses.data.length > 0 &&
              addressesStore.addresses.last_page > 1
                ? true
                : false
            "
            :current-page="addressesStore.addresses.current_page"
            :last-page="addressesStore.addresses.last_page"
            @paginate-event="filter($event)"
          />

          <!--  Address List -->
          <v-list
            v-if="addressesStore.addresses.data.length > 0"
            two-line
            class="pt-0"
          >
            <template v-for="(item, i) in addressesStore.addresses.data">
              <v-list-item :key="i" @click="editAddress(item)">
                <v-list-item-content>
                  <v-list-item-title
                    v-if="usersStore.authUser.customers.length > 0"
                    class="font-weight-bold"
                  >
                    {{ item.customer.d_companyName }}
                  </v-list-item-title>

                  <v-list-item-title class="text--primary">
                    {{ item.d_locationName }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    >{{ item.d_company }} - {{ item.d_street1 }}
                    {{ item.d_city }}, {{ item.d_state }}
                    {{ item.d_zip }}</v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text
                    :class="
                      'text-xs-right subtitle-1 ' +
                        [
                          item.f_status === 1
                            ? 'green--text lighten-1--text'
                            : 'error--text'
                        ]
                    "
                  >
                    {{ translateStatus(item.f_status) }}
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
              <v-divider />
            </template>
          </v-list>

          <v-list v-else>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="red--text subtitle-1 text-xs-center">
                  No Addresses Exist
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mixin } from "@/mixins/mixin.js";
import CarrierAccountEntry from "@/components/CarrierAccountEntry";
import AddressEntry from "@/components/AddressEntry";
import Pagination from "@/components/Pagination";

export default {
  name: "Shipping",
  components: {
    AddressEntry,
    CarrierAccountEntry,
    Pagination
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      customersStore: state => state.customersStore,
      carrierAccountsStore: state => state.carrierAccountsStore,
      addressesStore: state => state.addressesStore
    })
  },
  data() {
    return {
      progress: false,
      addressFilter: "",
      filterBar: {
        customerID: "",
        locationName: "",
        company: ""
      },
      carrierAccount: {
        mode: "",
        open: "",
        valid: true,
        id: "",
        fk_customerID: "",
        fk_shippingCarrierID: "",
        d_account: "",
        d_companyName: "",
        d_street1: "",
        d_street2: "",
        d_city: "",
        d_state: "",
        d_zip: "",
        d_country: "",
        f_default: "",
        f_status: ""
      },
      address: {
        mode: "",
        open: "",
        valid: true,
        id: "",
        fk_customerID: "",
        d_locationName: "",
        d_company: "",
        d_street1: "",
        d_street2: "",
        d_attention: "",
        d_city: "",
        d_state: "",
        d_zip: "",
        d_country: "",
        f_status: "",
        f_shipping: "",
        f_return: "",
        f_default: "",
        f_show: ""
      }
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.progress = true;
      const user = this.$store.getters["usersStore/getAuthUser"];

      const filters = this.$store.getters["addressesStore/getFilters"];

      this.filterBar.customerID = filters.customerID;
      this.filterBar.locationName = filters.locationName;
      this.filterBar.company = filters.company;

      const data = {
        fk_customerID: user.fk_customerID,
        fk_userID: user.id,
        customerID: this.filterBar.customerID,
        locationName: this.filterBar.locationName,
        company: this.filterBar.company,
        page: filters.page
      };

      if (!user) {
        this.$router.push({ name: "logout" });
      } else {
        await this.$store.dispatch(
          "carrierAccountsStore/fetchByCustomer",
          data
        );
        await this.$store.dispatch("addressesStore/fetchByCustomer", data);
        this.progress = false;
      }
    },

    editCarrierAccount(item) {
      this.carrierAccount.mode = 2;
      this.carrierAccount.id = item.id;
      this.carrierAccount.fk_shippingCarrierID = item.fk_shippingCarrierID;
      this.carrierAccount.d_account = item.d_account;
      this.carrierAccount.d_company = item.d_company;
      this.carrierAccount.d_street1 = item.d_street1;
      this.carrierAccount.d_street2 = item.d_street2;
      this.carrierAccount.d_city = item.d_city;
      this.carrierAccount.d_state = item.d_state;
      this.carrierAccount.d_zip = item.d_zip;
      this.carrierAccount.d_country = item.d_country;
      this.carrierAccount.f_default = item.f_default;
      this.carrierAccount.f_status = item.f_status;
      this.carrierAccount.open = true;
    },

    editAddress(item) {
      this.address.mode = 2;
      this.address.valid = true;
      this.address.id = item.id;
      this.address.fk_customerID = item.fk_customerID;
      this.address.d_locationName = item.d_locationName;
      this.address.d_company = item.d_company;
      this.address.d_street1 = item.d_street1;
      this.address.d_street2 = item.d_street2;
      this.address.d_attention = item.d_attention;
      this.address.d_city = item.d_city;
      this.address.d_state = item.d_state;
      this.address.d_zip = item.d_zip;
      this.address.d_country = item.d_country;
      this.address.f_shipping = item.f_shipping;
      this.address.f_return = item.f_return;
      this.address.f_default = item.f_default;
      this.address.f_status = item.f_status;
      this.address.f_show = item.f_show;
      this.address.open = true;
    },

    async filter(page) {
      this.addressFilter = false;
      const user = this.$store.getters["usersStore/getAuthUser"];

      const data = {
        fk_customerID: user.fk_customerID,
        fk_userID: user.id,
        customerID: this.filterBar.customerID,
        locationName: this.filterBar.locationName,
        company: this.filterBar.company,
        page
      };
      this.$store.dispatch("addressesStore/storeFilters", data);
      await this.$store.dispatch("addressesStore/fetchByCustomer", data);
    },

    async clearFilter() {
      this.addressFilter = false;
      const user = this.$store.getters["usersStore/getAuthUser"];

      this.filterBar.customerID = "";
      this.filterBar.locationName = "";
      this.filterBar.company = "";

      const data = {
        fk_customerID: user.fk_customerID,
        fk_userID: user.id,
        customerID: this.filterBar.customerID,
        locationName: this.filterBar.locationName,
        company: this.filterBar.company,
        page: 1
      };
      this.$store.dispatch("addressesStore/storeFilters", data);
      await this.$store.dispatch("addressesStore/fetchByCustomer", data);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
