<template>
  <div>
    <!--New Button-->
    <v-btn rounded color="green lighten-1 white--text" @click="newEntry()">
      <v-icon small left>
        fal fa-plus </v-icon
      >New
    </v-btn>

    <!--Entry Form-->
    <v-dialog v-model="entry.open" persistent max-width="600">
      <v-card>
        <v-card-title class="secondary white--text headline font-weight-medium">
          Carrier Account
          <v-spacer />
          <v-btn text icon color="white" @click="entry.open = false">
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="entryForm" v-model="entry.valid" lazy-validation>
            <v-container fluid grid-list-sm>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-select
                    v-model="entry.fk_shippingCarrierID"
                    :items="shippingCarriers"
                    item-text="d_name"
                    item-value="fk_internalID"
                    label="Shipping Carrier"
                    placeholder=" "
                    persistent-placeholder
                    required
                    :rules="rules.entry.fk_shippingCarrierID"
                  />
                </v-flex>

                <v-flex xs12>
                  <v-text-field
                    v-model="entry.d_account"
                    label="Account Number"
                    placeholder=" "
                    persistent-placeholder
                    required
                    :rules="rules.entry.d_account"
                  />
                </v-flex>

                <v-flex xs12>
                  <v-text-field
                    v-model="entry.d_company"
                    label="Company Name"
                    placeholder=" "
                    persistent-placeholder
                    required
                    :rules="rules.entry.d_company"
                  />
                </v-flex>

                <v-flex xs12>
                  <v-text-field
                    v-model="entry.d_street1"
                    label="Address 1"
                    placeholder=" "
                    persistent-placeholder
                    required
                    :rules="rules.entry.d_street1"
                  />
                </v-flex>

                <v-flex xs12>
                  <v-text-field
                    v-model="entry.d_street2"
                    label="Address 2"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-flex>

                <v-flex xs12 sm7>
                  <v-text-field
                    v-model="entry.d_city"
                    label="City"
                    placeholder=" "
                    persistent-placeholder
                    required
                    :rules="rules.entry.d_city"
                  />
                </v-flex>

                <v-flex xs12 sm2>
                  <v-text-field
                    v-model="entry.d_state"
                    label="State"
                    placeholder=" "
                    persistent-placeholder
                    required
                    :rules="rules.entry.d_state"
                  />
                </v-flex>

                <v-flex xs12 sm3>
                  <v-text-field
                    v-model="entry.d_zip"
                    label="Postal Code"
                    placeholder=" "
                    persistent-placeholder
                    required
                    :rules="rules.entry.d_zip"
                  />
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex xs12 sm3>
                  <v-switch
                    v-model="entry.f_default"
                    label="Default"
                    color="green lighten-1"
                  />
                </v-flex>
              </v-layout>

              <v-layout v-if="entry.mode === 2" row wrap>
                <v-flex xs12 sm3>
                  <v-switch
                    v-model="entry.f_status"
                    label="Status"
                    color="green lighten-1"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn rounded block color="primary" @click="saveEntry()">
            <v-icon small left>
              fal fa-arrow-alt-down </v-icon
            >Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CarrierAccountEntry",
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      addressesStore: state => state.addressesStore
    })
  },
  props: {
    entry: {
      type: Object,
      default: {
        mode: "",
        open: false,
        valid: true,
        id: "",
        fk_customerID: "",
        fk_shippingCarrierID: "",
        d_account: "",
        d_companyName: "",
        d_street1: "",
        d_street2: "",
        d_city: "",
        d_state: "",
        d_zip: "",
        d_country: "",
        f_default: "",
        f_status: ""
      }
    }
  },
  data() {
    return {
      shippingCarriers: [
        {
          fk_internalID: 1,
          d_name: "UPS"
        },
        {
          fk_internalID: 2,
          d_name: "FedEx"
        },
        {
          fk_internalID: 3,
          d_name: "USPS"
        },
        {
          fk_internalID: 4,
          d_name: "Courier"
        }
      ],
      rules: {
        entry: {
          fk_shippingCarrierID: [v => !!v || "required"],
          d_account: [v => !!v || "required"],
          d_companyName: [v => !!v || "required"],
          d_street1: [v => !!v || "required"],
          d_city: [v => !!v || "required"],
          d_state: [v => !!v || "required"],
          d_zip: [v => !!v || "required"],
          d_country: [v => !!v || "required"],
          f_status: [v => !!v || "required"]
        }
      }
    };
  },

  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.entry.valid = true;
      this.entry.open = false;
    },

    close() {
      this.entry.open = false;
    },

    newEntry() {
      this.entry.mode = 1;
      this.entry.valid = true;
      this.entry.f_status = 1;
      this.entry.id = "";
      this.entry.fk_customerID = "";
      this.entry.fk_shippingCarrierID = "";
      this.entry.d_account = "";
      this.entry.d_companyName = "";
      this.entry.d_street1 = "";
      this.entry.d_street2 = "";
      this.entry.d_city = "";
      this.entry.d_state = "";
      this.entry.d_zip = "";
      this.entry.d_country = "";
      this.entry.f_default = "";
      this.entry.f_status = true;
      this.entry.open = true;
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const customer = this.$store.getters["customersStore/getCustomer"];
        this.entry.fk_customerID = customer.id;

        //* If new carrier account */
        if (this.entry.mode === 1) {
          await this.$store.dispatch("carrierAccountsStore/create", this.entry);
        } else {
          await this.$store.dispatch("carrierAccountsStore/update", this.entry);
        }
        const data = {
          fk_customerID: customer.id,
          fk_shippingCarrierID: this.entry.fk_shippingCarrierID
        };
        await this.$store.dispatch(
          "carrierAccountsStore/fetchByCustomerCarrier",
          data
        );
        this.close();
      }
    }
  }
};
</script>

<style scoped>
button {
  outline: none;
}
</style>
