<template>
  <div>
    <!--New Button-->
    <v-btn rounded color="green lighten-1 white--text" @click="newEntry()">
      <v-icon small left> fal fa-plus </v-icon>New
    </v-btn>

    <!--Entry Form-->
    <v-dialog v-model="entry.open" scrollable persistent max-width="700">
      <v-card>
        <v-card-title class="secondary white--text headline font-weight-medium">
          Address
          <v-spacer />
          <v-btn text icon color="white" @click="entry.open = false">
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="entryForm" v-model="entry.valid" lazy-validation>
            <v-container fluid grid-list-sm>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="entry.d_locationName"
                    label="Location Name"
                    placeholder=" "
                    persistent-placeholder
                    required
                    :rules="rules.entry.d_locationName"
                  />
                </v-flex>

                <v-flex xs12>
                  <v-text-field
                    v-model="entry.d_company"
                    label="Company Name"
                    placeholder=" "
                    persistent-placeholder
                    required
                    :rules="rules.entry.d_company"
                  />
                </v-flex>

                <v-flex xs12>
                  <v-text-field
                    v-model="entry.d_attention"
                    label="Attention"
                    placeholder=" "
                    persistent-placeholder
                    required
                    :rules="rules.entry.d_attention"
                  />
                </v-flex>

                <v-flex xs12>
                  <v-text-field
                    v-model="entry.d_street1"
                    label="Address 1"
                    placeholder=" "
                    persistent-placeholder
                    required
                    :rules="rules.entry.d_street1"
                  />
                </v-flex>

                <v-flex xs12>
                  <v-text-field
                    v-model="entry.d_street2"
                    label="Address 2"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-flex>

                <v-flex xs12 sm6>
                  <v-text-field
                    v-model="entry.d_city"
                    label="City"
                    placeholder=" "
                    persistent-placeholder
                    required
                    :rules="rules.entry.d_city"
                  />
                </v-flex>

                <v-flex xs12 sm2>
                  <v-text-field
                    v-model="entry.d_state"
                    label="State"
                    placeholder=" "
                    persistent-placeholder
                    required
                    :rules="rules.entry.d_state"
                  />
                </v-flex>

                <v-flex xs12 sm2>
                  <v-text-field
                    v-model="entry.d_zip"
                    label="Postal Code"
                    placeholder=" "
                    persistent-placeholder
                    required
                    :rules="rules.entry.d_zip"
                  />
                </v-flex>

                <v-flex xs12 sm2>
                  <v-text-field
                    v-model="entry.d_country"
                    label="Country"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <v-switch
                    v-model="entry.f_shipping"
                    label="Shipping Address"
                    color="green lighten-1"
                  />
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <v-switch
                    v-model="entry.f_return"
                    label="Return Address"
                    color="green lighten-1"
                  />
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex xs12 sm3>
                  <v-switch
                    v-model="entry.f_default"
                    label="Default"
                    placeholder=" "
                    persistent-placeholder
                    color="green lighten-1"
                  />
                </v-flex>
              </v-layout>

              <v-layout v-if="entry.mode === 2" row wrap>
                <v-flex xs12 sm3>
                  <v-switch
                    v-model="entry.f_status"
                    label="Status"
                    color="green lighten-1"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn rounded block color="primary" @click="saveEntry()">
            <v-icon small left> fal fa-arrow-alt-down </v-icon>Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AddressEntry",
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      addressesStore: state => state.addressesStore
    })
  },
  props: {
    entry: {
      type: Object,
      default: {
        mode: "",
        open: false,
        valid: true,
        id: "",
        fk_customerID: "",
        d_locationName: "",
        d_company: "",
        d_street1: "",
        d_street2: "",
        d_attention: "",
        d_city: "",
        d_state: "",
        d_zip: "",
        d_country: "",
        f_status: "",
        f_shipping: "",
        f_return: "",
        f_default: "",
        f_show: ""
      }
    },
    origin: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      rules: {
        entry: {
          d_locationName: [v => !!v || "required"],
          d_company: [v => !!v || "required"],
          d_street1: [v => !!v || "required"],
          d_city: [v => !!v || "required"],
          d_state: [v => !!v || "required"],
          d_zip: [v => !!v || "required"],
          d_country: [v => !!v || "required"]
        }
      }
    };
  },

  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.entry.valid = true;
      this.entry.open = false;
    },

    close() {
      this.entry.open = false;
    },

    newEntry() {
      this.entry.mode = 1;
      this.entry.valid = true;
      this.entry.id = "";
      this.entry.fk_customerID = "";
      this.entry.d_locationName = "";
      this.entry.d_company = "";
      this.entry.d_street1 = "";
      this.entry.d_street2 = "";
      this.entry.d_attention = "";
      this.entry.d_city = "";
      this.entry.d_state = "";
      this.entry.d_zip = "";
      this.entry.d_country = "";
      this.entry.f_status = true;
      this.entry.f_shipping = "";
      this.entry.f_return = "";
      this.entry.f_default = "";
      this.entry.f_show = "";
      this.entry.open = true;
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const user = this.$store.getters["usersStore/getAuthUser"];
        this.entry.fk_customerID = user.fk_customerID;
        /* If adding a new entry */
        if (this.entry.mode === 1) {
          if (this.origin === 1) {
            await this.$store.dispatch("addressesStore/createShip", this.entry);
          } else {
            await this.$store.dispatch("addressesStore/create", this.entry);
          }
        } else {
          await this.$store.dispatch("addressesStore/update", this.entry);
        }

        const data = {
          fk_customerID: user.fk_customerID
        };
        await this.$store.dispatch(
          "addressesStore/fetchByCustomerReturn",
          data
        );
        await this.$store.dispatch("addressesStore/fetchByCustomerShip", data);

        this.close();
      }
    }
  }
};
</script>

<style scoped>
button {
  outline: none;
}
</style>
